import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/Logo.png';
import '../styles/template.css';

const PrivacyPage = () => {
  return (
    <div className="container">
      <header>
        <div className="logo-container">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <h1>Privacy Policy</h1>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/connect">Connect</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </nav>
      </header>
      <main>
        <h2>Our Commitment to Your Privacy</h2>
        <p>
          At Know Yourself, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our website, mobile application, and services (collectively, the "Services"). By using our Services, you consent to the practices described in this policy.
        </p>
        <h2>Information We Collect</h2>
        <ol>
          <li>
            <h3>Personal Information</h3>
            <p>
              When you create an account, we may collect your name, email address, and other contact information you provide.
            </p>
          </li>
          <li>
            <h3>Usage Data</h3>
            <p>
              We may collect information about how you use our Services, including your interactions with the app, pages visited, and features used.
            </p>
          </li>
          <li>
            <h3>Device Information</h3>
            <p>
              We may collect information about the devices you use to access our Services, such as the device type, operating system, and unique device identifiers.
            </p>
          </li>
        </ol>
        <h2>How We Use Your Information</h2>
        <ol>
          <li>
            <h3>Provide and Improve Services</h3>
            <p>
              We use your information to deliver, maintain, and improve our Services, personalize your experience, and respond to your inquiries.
            </p>
          </li>
          <li>
            <h3>Communication</h3>
            <p>
              We may use your email address to send you important updates, notifications, and marketing communications related to our Services. You can opt out of receiving marketing emails at any time.
            </p>
          </li>
          <li>
            <h3>Analytics and Research</h3>
            <p>
              We may use aggregated and anonymized data for analytics, research, and product improvement purposes.
            </p>
          </li>
          <li>
            <h3>Legal Compliance</h3>
            <p>
              We may use and disclose your information as required by law or to protect our rights, property, or safety, or that of our users or the public.
            </p>
          </li>
        </ol>
        <h2>Information Sharing and Disclosure</h2>
        <ol>
          <li>
            <h3>Service Providers</h3>
            <p>
              We may share your information with trusted third-party service providers who assist us in operating our Services and conducting our business, subject to confidentiality obligations.
            </p>
          </li>
          <li>
            <h3>Business Transfers</h3>
            <p>
              In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.
            </p>
          </li>
          <li>
            <h3>Legal Requirements</h3>
            <p>
              We may disclose your information if required by law, court order, or governmental request, or to protect our rights, property, or safety, or that of our users or the public.
            </p>
          </li>
        </ol>
        <h2>Data Security</h2>
        <p>
          We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
        <h2>Your Choices</h2>
        <ol>
          <li>
            <h3>Opt-out of Marketing Communications</h3>
            <p>
              You can opt out of receiving marketing emails by following the unsubscribe instructions in the emails or contacting us directly.
            </p>
          </li>
        </ol>
        <h2>Children's Privacy</h2>
        <p>
          Our Services are not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information.
        </p>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of the policy.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at support@know-yourself-project.com.
        </p>
      </main>
      <footer>
        <p>&copy; 2024 Know Yourself LLC | All rights reserved.</p>
      </footer>
    </div>
  );
};

export default PrivacyPage;
