import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/Logo.png';
import GrantImage from '../images/Grant.jpg';
import IntelligenceTypeImage from '../images/IntelligenceTypeImage.jpg';
import MyTypes from '../images/MyTypes.PNG';
import NotableFiguresImage from '../images/NotableFiguresImage.jpg';
import IntelTestImage from '../images/IntelTestImage.jpg';
import MapImage from '../images/MapImage.jpg';
import DestinationImage from '../images/DestinationImage.jpg';
import FilterImage from '../images/FilterImage.jpg';
import ChartImage from '../images/Chart.jpg';
import '../styles/template.css';

const AboutPage = () => {
  return (
    <div className="container">
      <header>
        <div className="logo-container">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <h1>About Us</h1>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/connect">Connect</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </nav>
      </header>
      <main>
        <h2>How it Works</h2>
        <p>
          Know Yourself uses the Theory of Multiple Intelligences as a framework for users to learn their own intelligence types and connect those insights to the real world. The Theory of Multiple Intelligences was created by psychologist Howard Gardner with the education system in mind; to aid instructors in understanding the variety of intelligences students possess, and cater to each of their diverse thinking styles.
        </p>
        <img src={ChartImage} alt="Intelligence Chart" className="chart-image" />
        <p>
          After downloading the Know Yourself App, take an assessment to reveal your intelligence types. 
        </p>
        <div className="image-row">
          <img src={IntelTestImage} alt="Intelligence Assessment" className="image-row-item" />
          <img src={MyTypes} alt="My Intelligence Types" className="image-row-item" />
        </div>
        <p>Learn more about each type, and inspirational individuals with each type of intelligence.</p>
        <div className="image-row">
          <img src={IntelligenceTypeImage} alt="Intelligence Type Description" className="image-row-item" />
          <img src={NotableFiguresImage} alt="Notable Figures" className="image-row-item" />
        </div>
        <p>
          Know Yourself provides destinations catered to you. Pick up a new hobby, re-discover a lost passion, or just find a fun activity to do on the weekend.
        </p>
        <div className="image-row">
          <img src={MapImage} alt="Map" className="image-row-item" />
          <img src={DestinationImage} alt="Destination" className="image-row-item" />
          <img src={FilterImage} alt="Filter" className="image-row-item" />
        </div>
        <h2>________________________</h2>
        <div className="team-member">
          <img src={GrantImage} alt="Grant Reynolds - Know Yourself CEO" className="profile-image" />
          <h3>Founder / CEO - Grant Reynolds</h3>
          <p>
            I've met all sorts of people with different types of intelligences. Unfortunately, many of them are not pursuing work or hobbies that align with their gifts, and may not be aware of their potential. Without this knowledge, it is difficult to find meaning and motivation.
          </p>
          <p>
            To make the most out of life, it's helpful to find activities that align with our natural strengths and how we think.
          </p>
          <p>
            I started this project to serve as a roadmap and catalyst for this discovery. To encourage the quest for self-realization through introspection, but also out in the real world, in our communities.
          </p>
        </div>
      </main>
      <footer>
        <p>&copy; 2024 Know Yourself LLC | All rights reserved.</p>
      </footer>
    </div>
  );
};

export default AboutPage;
