import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/Logo.png'; // Import the logo image
import '../styles/template.css';

const ContactPage = () => {
  return (
    <div className="container">
      <header>
        <div className="logo-container">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <h1>Contact Us</h1>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/connect">Connect</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </nav>
      </header>
      <main>
        <h2>Get in Touch</h2>
        <p>
          If you have any questions, feedback, or inquiries, please don't hesitate to reach out to us. We'd love to hear from you!
        </p>
        <p>
          You can contact our team via email at <a href="mailto:support@know-yourself-project.com">support@know-yourself-project.com</a>.
        </p>
        <p>
          Connect with us on social media:
        </p>
        <ul>
          <li><a href="https://www.instagram.com/knowyourself_project" target="_blank" rel="noopener noreferrer">Instagram</a></li>
        </ul>
        <p>
          We appreciate your interest, and look forward to connecting with you soon!
        </p>
      </main>
      <footer>
        <p>&copy; 2024 Know Yourself LLC | All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ContactPage;
