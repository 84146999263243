import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import ConnectPage from './pages/ConnectPage';
import PrivacyPage from './pages/PrivacyPage'; // Import the PrivacyPage component
import './styles/template.css';

try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::545622008882:role/RUM-Monitor-us-east-1-545622008882-1405675798071-Unauth",
    identityPoolId: "us-east-1:1f9c9cb0-c450-4708-b9e8-fe3c9280f3a3",
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
  };
  const APPLICATION_ID: string = 'adf66f05-9f5f-4fed-9a7d-4988686cb08c';
  const APPLICATION_VERSION: string = '1.0.0';
  const APPLICATION_REGION: string = 'us-east-1';
  new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/connect" element={<ConnectPage />} />
        <Route path="/privacy" element={<PrivacyPage />} /> 
      </Routes>
    </Router>
  );
}

export default App;
